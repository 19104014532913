.list-container {
  background-color: var(--theme-bg-1);
  grid-area: list;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  justify-content: space-between;
  border: 2px solid var(--theme-bg-1);
  overflow: hidden;
}

.list {
  /* padding: 10px; */
  overflow-y: auto;
}

.list::-webkit-scrollbar {
  background-color: var(--theme-bg-2);
  width: 3px;
}

.list::-webkit-scrollbar-thumb {
  background-color: var(--theme-highlight-1);
  border-radius: 10px;
  outline: none;
}

.list-item {
  background-color: var(--theme-bg-3);
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  grid-template-areas:
    'listRemote listTitle listAlert listCTA listAtionToggle'
    'listActions listActions listActions listActions listActions';
}
.list-item:hover,
.list-item:focus {
  outline: none;
}

.list-item:hover .list-item-title,
.list-item:focus .list-item-title {
  color: var(--theme-highlight-1);
}

.list-item-remote {
  grid-area: listRemote;
  font-weight: bold;
  font-size: smaller;
}

.list-item-title {
  grid-area: listTitle;
}

.list-item-alert {
  grid-area: listAlert;
}

.list-item-cta {
  grid-area: listCTA;
}

.list-item-remote,
.list-item-title,
.list-item-alert,
.list-item-cta {
  padding: 10px;
}

.list-item-remote.active {
  background-color: var(--theme-highlight-1);
  color: var(--theme-bg-1);
  border-radius: 3px;
  margin: 2px;
  padding: 8px;
}

.list-item-actions {
  grid-area: listActions;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: var(--theme-bg-2);
  border: 1px 0px 0px 1px solid var(--theme-bg-1);
}

.list-item-action-toggle {
  box-sizing: border-box;
  grid-area: listAtionToggle;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.action {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  border-bottom: 1px solid var(--theme-bg-1);
}

.action:hover,
.action:focus {
  outline: none;
  color: var(--theme-highlight-1);
  border-radius: 3px;
}

.details {
  grid-area: details;
}
.preview {
  grid-area: preview;
}
.actions {
  grid-area: actions;
}

.upload {
  background-color: var(--theme-highlight-1);
  color: var(--theme-bg-1);
  padding: 10px;
  border: none;
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* TOOD: Mobile styles */
