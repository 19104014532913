.screen {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  gap: 10px;
  grid-template-areas:
    'list details'
    'list preview'
    'list actions';
}

/* In mobile screens */
@media (max-width: 600px) {
  .screen {
    height: 100%;
    width: 100%;
    padding: 20px;
    grid-template:
      'details' auto
      'preview' auto
      'list' 1fr
      'actions' auto;
  }
  .list {
    margin-top: 20px;
  }
  .details {
    margin-bottom: 20px;
  }
  .preview {
    margin-bottom: 20px;
  }
  .actions {
    margin-top: 20px;
  }
}
